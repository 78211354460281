import {
    Show as ReactAdminShow,
    SimpleShowLayout,
    BooleanField,
    DateField,
    TextField,
    ArrayField,
    Datagrid,
    TopToolbar,
    EditButton,
    useRecordContext
} from "react-admin";
import { DownloadBundleButton } from "../../components/buttons/DownloadBundleButton";
import { ExtensionIconField } from "../../components/fields/icon/ExtensionIconField";

export const Show: React.FC = () => (
    <ReactAdminShow actions={<ShowActions />}>
        <SimpleShowLayout>
            <TextField source="Version" />
            <TextField source="MinApiVersion" />
            <TextField source="MaxApiVersion" emptyText="Current" />
            <BooleanField source="IsTestFlightOnly" />
            <DateField source="InsertedAt" label="Uploaded At" />
            <ArrayField source="Extensions">
                <Datagrid>
                    <TextField source="Name" />
                    <TextField source="ComponentName" />
                    <TextField source="MinBundleVersion" />
                    <TextField source="MaxBundleVersion" emptyText="Current" />
                    <TextField source="RequiredClaims" emptyText="None" />
                    <ExtensionIconField source="Icons" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </ReactAdminShow>
);

const ShowActions: React.FC = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <DownloadBundleButton />
            <EditButton record={record} />
        </TopToolbar>
    );
};
