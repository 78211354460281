import axios from "axios";
import { useEffect, useState } from "react";

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * This hook fetches our available Stripe Array and returns an array
 * @returns {Array<T>} an array for Coupon objects
 */
export const useGetArray = <T = any>(
    path: string
): { data: Array<T>; loading: boolean } => {
    const [array, setArray] = useState<Array<T>>([]);

    const [userInfo] = useState(JSON.parse(localStorage.getItem("usrtikt")!));

    useEffect(() => {
        const getArray = async () => {
            const array = await axios({
                url: `${apiUrl}/${path}`,
                method: "GET",
                headers: {
                    Authorization: `bearer ${userInfo.access_token}`,
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    return response.data;
                })
                .catch((err) => {
                    if (err instanceof Error) {
                        throw err;
                    }
                    throw new Error(`${err}`);
                });
            setArray(array);
        };
        getArray();
    }, [path, userInfo.access_token]);

    return { data: array, loading: !array };
};
