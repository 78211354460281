import {
    Edit as ReactAdminEdit,
    SimpleForm,
    TextInput,
    BooleanInput
} from "react-admin";

export const Edit: React.FC = () => (
    <ReactAdminEdit>
        <SimpleForm>
            <TextInput source="MinApiVersion" />
            <TextInput source="MaxApiVersion" label="Current" />
            <BooleanInput source="IsTestFlightOnly" />
        </SimpleForm>
    </ReactAdminEdit>
);
