import * as React from "react";
import {
    List as ReactAdminList,
    Datagrid,
    TextField,
    DateField,
    DeleteButton
} from "react-admin";

export const List: React.FC = () => (
    <ReactAdminList>
        <Datagrid rowClick="edit">
            <TextField source="Domain" />
            <DateField source="CreatedAt" label="Created At" />
            <DeleteButton />
        </Datagrid>
    </ReactAdminList>
);
