import * as React from "react";
import { useCallback } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { client } from "../../api/client";

/**
 * A button for fulfilling multi-selected Deletion Requests. For use in DeletionRequestList bulkActionsButtons.
 */
export const BulkFulfillDeletionRequestButton: React.FC<any> = ({
    selectedIds
}) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const fulfill = useCallback(() => {
        if (selectedIds.length > 0) {
            Promise.allSettled(
                selectedIds.map((id: string) => client.user_DeleteUserById(id))
            ).then((results) => {
                const rejected = results.filter(
                    (result) => result.status === "rejected"
                );
                if (rejected.length > 0) {
                    notify("Some users were not deleted, try running again", {
                        type: "warning"
                    });
                    refresh();
                } else {
                    notify("All selected users were deleted", {
                        type: "success"
                    });
                    refresh();
                }
            });
        }
    }, [selectedIds, notify, refresh]);

    return <Button label="Fulfill Selected" onClick={fulfill} />;
};
