import {
    List as ReactAdminList,
    Datagrid,
    TextField,
    ChipField,
    ShowButton
} from "react-admin";

export const List: React.FC = () => (
    <ReactAdminList>
        <Datagrid rowClick="show">
            <TextField source="Name" />
            <ChipField source="Status" />
            <ShowButton />
        </Datagrid>
    </ReactAdminList>
);
