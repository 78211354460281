import { ResellerInventory } from "@switcherstudio/switcher-api-client";
import * as React from "react";
import { useCallback } from "react";
import { Button, useNotify } from "react-admin";
import { useGetAvailableCode } from "../../hooks/useGetAvailableCode";

export interface CopyAnyOrUnifiedCodeButtonProps {
    record: ResellerInventory;
}

/**
 * This is a button that allows a user to copy a code for either a single available code or
 * the common code for all Reseller Inventory Items in a Reseller Inventory.
 */
export const CopyAnyOrUnifiedCodeButton: React.FC<
    CopyAnyOrUnifiedCodeButtonProps
> = ({ record }) => {
    const notify = useNotify();
    const code = useGetAvailableCode(record as ResellerInventory);

    const copyCode = useCallback(() => {
        if (code) {
            navigator.clipboard.writeText(code);
            notify(`${code} was copied to the clipboard`, { type: "success" });
        }
    }, [code, notify]);

    return code ? <Button label="Copy Code" onClick={copyCode} /> : null;
};
