import { useCallback } from "react";
import {
    Create as ReactAdminCreate,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect
} from "react-admin";
import { getQueryParam } from "../../helpers/queryParams";

export const Create: React.FC = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = useCallback(() => {
        notify(`User is now connected to this Reseller`);
        const parentId = getQueryParam("parentId");
        redirect(parentId ? `/Resellers/${parentId}/show/1` : `/Reseller`);
    }, [notify, redirect]);

    return (
        <ReactAdminCreate mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <TextInput source="UserEmail" label="User Email" />
            </SimpleForm>
        </ReactAdminCreate>
    );
};
