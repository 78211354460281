import { Extension } from "@switcherstudio/switcher-api-client";
import { UpdateParams } from "react-admin";
import { apiUrl } from ".";
import { getQueryParam } from "../../helpers/queryParams";
import { httpClient } from "./httpClient";

export const update = async (resource: string, params: UpdateParams<any>) => {
    let url: string;
    let queries: any = {};
    const resources = resource.split("-");

    if (resources.length === 1) {
        url = `${apiUrl}/api/${resources[0]}/${params.id}`;
    } else if (resources.length === 2) {
        const parentId = params.meta.parentId ?? getQueryParam("parentId");

        if (parentId) {
            url = `${apiUrl}/api/${resources[0]}/${parentId}/${resources[1]}/${params.id}`;
        } else {
            url = `${apiUrl}/api/${resources.pop()}/${params.id}`;
        }
    } else {
        url = "";
    }

    switch (resource) {
        case "Resellers-Inventories":
            const quantityToAdd =
                params.data.ResellerInventoryTotalCount -
                params.previousData.ResellerInventoryTotalCount;
            if (quantityToAdd > 0) {
                queries.quantityToAdd = quantityToAdd;
            }
            break;
        default:
            break;
    }

    const options = {
        method: "PUT",
        body: JSON.stringify(formatBodyUpdate(resource, params.data))
    };

    return httpClient(url, options, queries).then(({ status, json }) => {
        if (status === 204 || !json) {
            return {
                data: { id: params.id } // it won't take an undefined or null
            };
        }
        return {
            data: json
        };
    });
};

const formatBodyUpdate = (resource: string, data: any) => {
    if (data.Amount) {
        data.Amount *= 100;
    }

    if (data.RewardAmount) {
        data.RewardAmount *= 100;
    }

    switch (resource) {
        case "Extensions":
            return formatBodyUpdateExtensions(data);
        default:
            return data;
    }
};

const formatBodyUpdateExtensions = (data: Extension) => {
    return {
        ...data,
        Icons: data.Icons?.map((i) => {
            return typeof i === "string" ? { Id: i } : i;
        })
    };
};
