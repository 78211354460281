import { MouseEventHandler, useCallback, useEffect, useState } from "react"
import { client } from "../../api/client"
import { Feature } from "@switcherstudio/switcher-api-client";
import {
    Create as ReactAdminCreate,
    SimpleForm,
    TextInput,
    SelectInput,
    FormDataConsumer,
    SaveButton,
    Toolbar as ReactAdminToolbar,
    useNotify,
} from "react-admin";
import { useGetArray } from "../../hooks/useGetArray";
import ContentDelete from '@mui/icons-material/Delete';


export const UserClaimForm = () => {

    const [data, setData] = useState<any>({});
    const [userFound, setUserFound] = useState<boolean>(true);
    const [currentUserFeatures, setCurrentUserFeatures] = useState<Feature[]>([]);
    const features = useGetArray<Feature>("api/Features");
        
    const validate = useCallback(
        (values: any) => ({
            ...(!values.UserId && {
                UserId: "User ID is required"
            }),
            ...(!values.ClaimId && {
                ClaimId: "Claim ID is required"
            })
        }),
        []
    );

    const getUserFeatures = useCallback(async () => {
        const userClaims = await client.userClaims_GetUserClaims(data?.UserId);
        if(userClaims === null) {
            setUserFound(false);
            return;
        }
        setUserFound(true);
        const userFeatures = features?.data?.filter((feature) => {
            return userClaims?.some((userClaim) => {
                return userClaim.ClaimValue === feature.ClaimId
            });
        });
        setCurrentUserFeatures(userFeatures);
    }, [data?.UserId, features?.data]);

    const onSuccess = useCallback(() => {
        getUserFeatures()
    }, [getUserFeatures]);

    useEffect(() => {        
        if (!!data?.UserId) {
            getUserFeatures();
        }
    }, [data?.UserId, getUserFeatures])

    return (
        <ReactAdminCreate>
            <SimpleForm
                toolbar={<Toolbar data={data} validate={validate} onSuccess={onSuccess} />}
            >
                <FormDataConsumer>
                    {({ formData }) => {
                        setData(formData);
                    }}
                </FormDataConsumer>

                <TextInput
                    source="UserId"
                    label="User ID"
                    fullWidth
                />
                <SelectInput
                    source="ClaimId"
                    label="Select Feature"
                    choices={features?.data?.map((feature) => {
                        return { id: feature.ClaimId, name: feature.Description }
                    })}
                    defaultValue={0}
                    fullWidth
                />
                {!userFound && (
                    <div style={{color: "red", fontWeight: "bold"}}>USER NOT FOUND</div>
                )}

                {!!data?.UserId && userFound && (
                    <>
                        <h4 style={{marginBottom: "0"}}>Current User Features</h4>
                        <ul>
                            {
                                currentUserFeatures?.map((feature) => {
                                    return (
                                        <li>
                                            {feature.Description}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </>
                )}
            </SimpleForm>
        </ReactAdminCreate>
    )
}


export const Toolbar: React.FC<{
    data: any;
    validate: (values: any) => {
        UserId?: string | undefined;
        ClaimId?: string | undefined;
    };
    onSuccess: () => void;
}> = ({ data, validate, onSuccess }) => {
    
    const notify = useNotify();

    const handleSave = useCallback<MouseEventHandler<HTMLButtonElement>>(
        async (e) => {
            e.preventDefault();

            const errors = Object.values(validate(data));

            if (errors.length === 0) {
                try {
                    await client.userClaims_AddUserClaims({
                        UserIds: [data.UserId],
                        Claims: [{
                            ClaimType: "switcher:feature",
                            ClaimValue: data.ClaimId
                        }]
                    });
                } catch(e: any) {   
                    notify(e?.message, { type: "error" });
                }
                notify("Claim Successfully Added", { type: "success" });
                onSuccess && onSuccess();
            } else {
                notify(errors[0], { type: "warning" });
            }
        },
        [data, validate, notify, onSuccess]
    );

    const handleDelete = useCallback<MouseEventHandler<HTMLButtonElement>>(
        async (e) => {
            e.preventDefault();

            const errors = Object.values(validate(data));

            if (errors.length === 0) {
                try {
                    await client.userClaims_DeleteUserClaims({
                        UserIds: [data.UserId],
                        Claims: [{
                            ClaimType: "switcher:feature",
                            ClaimValue: data.ClaimId
                        }]
                    });
                } catch(e: any) {   
                    notify(e?.message, { type: "error" });
                }
                notify("Claim Successfully Removed", { type: "success" });
                onSuccess && onSuccess();
            } else {
                notify(errors[0], { type: "warning" });
            }
        },
        [data, validate, notify, onSuccess]
    );

    return (
        <ReactAdminToolbar
            style={{ display: "flex", gap: "20px" }}
        >
            <SaveButton label="Add Claim to User" onClick={handleSave} />
            <SaveButton icon={<ContentDelete />} label="Remove Claim from User" onClick={handleDelete} />
        </ReactAdminToolbar>
    );
};
