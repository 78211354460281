import { fetchUtils } from "react-admin";
import { isEmpty } from "lodash";
import queryString from "query-string";

export const httpClient = (
    url: any,
    options: fetchUtils.Options | undefined = {},
    queries: any = {}
) => {
    options.headers = new Headers({ Accept: "application/json" }); // headers passed in as params are overwritten

    const userInfo = JSON.parse(localStorage.getItem("usrtikt")!);

    // log any request in segment

    options.headers.set("Authorization", `bearer ${userInfo.access_token}`);

    return fetchUtils.fetchJson(
        `${url}${isEmpty(queries) ? "" : `?${queryString.stringify(queries)}`}`,
        options
    );
};
