import * as React from "react";
import { Button, useNotify, useRecordContext, useRefresh } from "react-admin";
import Delete from "@mui/icons-material/Delete";
import { useCallback, useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export interface DeleteChildButtonProps {
    /** the resource to connect to - is inserted into the url - ex. "Resellers-Users" */
    childResource: string;
    /** the property of the current record that is the id of the child object - ex. "UserId" */
    childProperty?: string;
    /** the id of the child object - required if childProperty is not present */
    childId?: string;
    /** the property of the current record that is the id of the parent object - ex. "ResellerId" */
    parentProperty: string;
}

/**
 * Inside of a Datagrid or another component with record context this button will delete the record of the given row
 */
export const DeleteChildButton: React.FC<DeleteChildButtonProps> = ({
    childResource,
    childProperty,
    childId,
    parentProperty
}) => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const [userInfo] = useState(JSON.parse(localStorage.getItem("usrtikt")!));

    const DeleteChild = useCallback(async () => {
        let url: string;

        const resources = childResource.split("-");

        if (resources.length === 1) {
            url = `${apiUrl}/api/${resources[0]}/${record.id}`;
        } else if (resources.length === 2) {
            if (childProperty || childId) {
                url = `${apiUrl}/api/${resources[0]}/${
                    record[parentProperty]
                }/${resources[1]}/${childId ?? record[childProperty ?? "id"]}`;
            } else {
                throw new Error("childId or childProperty must be given");
            }
        } else {
            url = "";
        }

        await axios({
            url,
            method: "DELETE",
            headers: {
                Authorization: `bearer ${userInfo.access_token}`,
                "Content-Type": "application/json"
            }
        })
            .then(() => {
                notify(
                    `${resources
                        .slice(-1)
                        .pop()
                        ?.slice(0, -1)} was removed from ${resources[0].slice(
                        0,
                        -1
                    )}`
                );
                refresh();
            })
            .catch((err) => {
                notify(
                    `${resources
                        .slice(-1)
                        .pop()
                        ?.slice(0, -1)} was removed from ${resources[0].slice(
                        0,
                        -1
                    )}`,
                    { type: "warning" }
                );
            });
    }, [
        record,
        childResource,
        childProperty,
        parentProperty,
        userInfo.access_token,
        notify,
        refresh,
        childId
    ]);

    return (
        <Button
            onClick={DeleteChild}
            label="Delete"
            style={{ color: "maroon" }}
        >
            <Delete />
        </Button>
    );
};
