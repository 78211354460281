export interface DisplayAmountOptions {
    locale?: string;
    signed?: boolean;
    compact?: boolean;
    roundUp?: boolean;
    toFixed?: number;
    useGrouping?: boolean;
}

export const displayAmount = (
    amount: number,
    {
        locale = "en-US",
        signed = false,
        compact = true,
        roundUp = true,
        toFixed = 2,
        useGrouping = true

    }: DisplayAmountOptions = {}
) => {
    return Intl.NumberFormat(locale, {
        style: signed ? "currency" : "decimal",
        currency: "USD",
        notation: compact ? "compact" : "standard",
        minimumFractionDigits: compact ? undefined : toFixed,
        maximumFractionDigits: compact ? undefined : toFixed,
        useGrouping
    }).format(roundUp ? Math.ceil(amount / 100) : (amount / 100));
};
