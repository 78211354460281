import { DataProvider, UpdateManyParams } from "react-admin";
import { getList } from "./getList";
import { getOne } from "./getOne";
import { getMany } from "./getMany";
import { getManyReference } from "./getManyReference";
import { update } from "./update";
import { create } from "./create";
import { deleteOne } from "./deleteOne";
import { deleteMany } from "./deleteMany";

export const apiUrl = process.env.REACT_APP_API_URL;

export const hasRelationshipToUser = ["Resellers"];

export const dataProvider: DataProvider = {
    getList,
    getOne,
    getMany,
    getManyReference,
    update,
    updateMany: function (resource: string, params: UpdateManyParams<any>) {
        throw new Error("Function not implemented.");
    },
    create,
    delete: deleteOne,
    deleteMany
};
