import {
    Edit as ReactAdminEdit,
    SimpleForm,
    TextInput,
    NumberInput
} from "react-admin";

export const Edit: React.FC = () => (
    <ReactAdminEdit>
        <SimpleForm>
            <TextInput source="Name" />
            <NumberInput source="MaximumDestinations" />
            <NumberInput source="MaximumUsers" />
            <NumberInput source="VideoChatSecondsOverride" />
        </SimpleForm>
    </ReactAdminEdit>
);
