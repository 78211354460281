import * as React from "react";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";

export interface DashboardShortcutCardProps {
    /** action to be taken when card is clicked */
    onClick: () => void;
    /** text to be displayed as header */
    header: string;
    /** text to be displayed in body */
    body?: string;
}

/**
 * This card can be used on the Dashboard page to allow quick access to specific actions.
 */
export const DashboardShortcutCard: React.FC<DashboardShortcutCardProps> = ({
    header,
    onClick,
    body
}) => (
    <Card style={styles.card}>
        <CardActionArea style={styles.actionArea} onClick={onClick}>
            <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    {header}
                </Typography>
                {body && (
                    <Typography variant="body2" color="text.secondary">
                        {body}
                    </Typography>
                )}
            </CardContent>
        </CardActionArea>
    </Card>
);

const styles = {
    card: {
        marginTop: "1rem",
        width: "16rem",
        height: "16rem"
    },
    actionArea: {
        width: "100%",
        height: "100%"
    }
};
