import {
    List as ReactAdminList,
    Datagrid,
    TextField,
    DateField,
    ShowButton
} from "react-admin";

export const List: React.FC = () => (
    <ReactAdminList>
        <Datagrid rowClick="edit">
            <TextField source="Name" />
            <DateField source="InsertedAt" />
            <DateField source="UpdatedAt" />
            <ShowButton />
        </Datagrid>
    </ReactAdminList>
);
