export function renameKey(object: any, oldKey: string, newKey: string) {
    if (object) {
        const propertyDescriptor = Object.getOwnPropertyDescriptor(
            object,
            oldKey
        );
        if (propertyDescriptor && oldKey !== newKey) {
            Object.defineProperty(object, newKey, propertyDescriptor);
            delete object[oldKey];
        }
    }
}
