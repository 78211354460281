import {
    Show as ReactAdminShow,
    SimpleShowLayout,
    EmailField,
    BooleanField
} from "react-admin";
import { ChipArrayField } from "../../components/fields/chips/ChipArrayField";

export const Show: React.FC = () => (
    <ReactAdminShow>
        <SimpleShowLayout>
            <EmailField source="Email" />
            <BooleanField
                source="IsEmailConfirmed"
                label="Email Is Confirmed"
            />
            <ChipArrayField source="Roles" label="Roles" />
            <ChipArrayField source="Claims" label="Claims" />
        </SimpleShowLayout>
    </ReactAdminShow>
);
