import { Asset, Feature } from "@switcherstudio/switcher-api-client";
import {
    Create as ReactAdminCreate,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput,
    ValidateForm
} from "react-admin";
import { validate } from ".";
import { useGetArray } from "../../hooks/useGetArray";

export const Create: React.FC = () => {
    const features = useGetArray<Feature>("api/Features");
    const icons = useGetArray<Asset>("api/Icons");

    return (
        <ReactAdminCreate>
            <SimpleForm validate={validate as ValidateForm}>
                <TextInput source="Name" />
                <TextInput source="ComponentName" />
                <TextInput source="MinBundleVersion" />
                <TextInput source="MaxBundleVersion" />
                <SelectInput
                    source="RequiredClaim"
                    choices={features.data}
                    optionValue="ClaimId"
                    optionText="Description"
                    disabled={features.loading}
                    emptyText="None"
                    defaultValue={undefined}
                    emptyValue={undefined}
                />
                <SelectArrayInput
                    source="Icons"
                    choices={icons.data}
                    optionValue="Id"
                    optionText="Name"
                    disabled={icons.loading}
                />
            </SimpleForm>
        </ReactAdminCreate>
    );
};
