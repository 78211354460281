import { DeletionRequest } from "@switcherstudio/switcher-api-client";
import * as React from "react";
import {
    Button,
    useNotify,
    useRefresh,
    useRecordContext,
    useDelete
} from "react-admin";
import { isAfter, subDays } from "date-fns";
import { useCallback } from "react";

export interface FulfillDeletionRequestButtonProps {
    gracePeriod: number;
    graceDisabled: boolean;
}

/**
 * A button that will fulfill the deletion request of a user, effectively deleting them from the database. Calls DELETE api/User/{id}
 */
export const FulfillDeletionRequestButton: React.FC<
    FulfillDeletionRequestButtonProps
> = ({ gracePeriod, graceDisabled }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext<DeletionRequest & { id: string }>();

    const [fulfill, { isLoading }] = useDelete(
        "Users",
        { id: record.id },
        {
            onSuccess: () => {
                notify("User was deleted", { type: "success" });
                refresh();
            },
            onError: () => {
                notify("Was unable to delete user", { type: "error" });
            }
        }
    );

    const isInGracePeriod = useCallback(() => {
        if (record.InsertedAt) {
            return (
                !graceDisabled &&
                isAfter(
                    new Date(record.InsertedAt),
                    subDays(new Date(), gracePeriod)
                )
            );
        }
    }, [record.InsertedAt, graceDisabled, gracePeriod]);

    return (
        <Button
            label="Fulfill"
            onClick={() => fulfill()}
            disabled={isLoading || isInGracePeriod()}
        />
    );
};
