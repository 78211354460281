import {
    ResellerInventoryItem,
    ResellerInventoryItemStatus
} from "@switcherstudio/switcher-api-client";
import * as React from "react";
import { useCallback } from "react";
import { Button, useNotify, useRecordContext } from "react-admin";

/**
 * A button for copying the coupon code of a reseller inventory item. For use inside of ResellerInventoryItemShow datagrid
 */
export const CopyCodeButton: React.FC = () => {
    const notify = useNotify();
    const { CouponCode, Status } = useRecordContext<ResellerInventoryItem>();

    const copyCode = useCallback(() => {
        if (CouponCode) {
            navigator.clipboard.writeText(CouponCode);
            notify(`${CouponCode} was copied to the clipboard`, {
                type: "success"
            });
        }
    }, [CouponCode, notify]);

    return (
        <Button
            label="Copy Code"
            onClick={copyCode}
            disabled={Status !== ResellerInventoryItemStatus._0}
        />
    );
};
