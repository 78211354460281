import { Asset } from "@switcherstudio/switcher-api-client";
import { useRecordContext } from "react-admin";
import { DeleteChildButton } from "../../buttons/DeleteChildButton";

export interface ExtensionIconFieldProps {
    source: string;
    removable?: boolean;
}

/**
 * Displays the first SVG icon on a given extension. can be used in a List component or a Show component
 */
export const ExtensionIconField: React.FC<ExtensionIconFieldProps> = ({
    source,
    removable
}) => {
    const record = useRecordContext();
    return record[source]
        ?.filter(
            (i: any) =>
                i.MimeType === "image/svg+xml" || i.MimeType === "image/svg"
        )
        .map((icon: Asset) => (
            <>
                <svg
                    height="64"
                    style={{ filter: "invert(50%) brightness(50%)" }}
                >
                    <image xlinkHref={icon.Url} height="64" />
                </svg>
                {removable && (
                    <DeleteChildButton
                        childResource="Extensions-Icons"
                        childId={icon.Id}
                        parentProperty="id"
                    />
                )}
            </>
        ));
};
