import { GetManyReferenceParams } from "react-admin";
import { apiUrl } from ".";
import { renameKey } from "./helpers";
import { httpClient } from "./httpClient";

export const getManyReference = async (
    resource: string,
    params: GetManyReferenceParams
) => {
    const resources = resource.split("-");
    const url = `${apiUrl}/api/${resources[0]}/${params.id}/${resources[1]}`;

    return httpClient(url).then(({ json }) => {
        json.forEach((object: any) => {
            renameKey(object, "Id", "id");
        });
        return {
            data: json,
            total: json.length
        };
    });
};
