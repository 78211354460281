import { DeleteParams } from "react-admin";
import { apiUrl } from ".";
import { getQueryParam } from "../../helpers/queryParams";
import { getResourceIdFromUrl } from "../../helpers/getResourceIdFromUrl";
import { httpClient } from "./httpClient";

export const deleteOne = async (resource: string, params: DeleteParams) => {
    let url: string;
    const resources = resource.split("-");

    if (resources.length === 1) {
        url = `${apiUrl}/api/${resources[0]}/${params.id}`;
    } else if (resources.length === 2) {
        const parentId =
            getQueryParam("parentId") || getResourceIdFromUrl(resources[0]);
        if (parentId) {
            url = `${apiUrl}/api/${resources[0]}/${parentId}/${resources[1]}/${params.id}`;
        } else {
            url = `${apiUrl}/api/${resources.pop()}/${params.id}`;
        }
    } else {
        url = "";
    }

    return httpClient(url, { method: "DELETE" }).then(({ json }) => {
        return {
            data: json
        };
    });
};
