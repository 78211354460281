import * as React from "react";
import {
    List as ReactAdminList,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    BooleanField
} from "react-admin";

export const List: React.FC = () => (
    <ReactAdminList>
        <Datagrid rowClick="show">
            <TextField source="Name" />
            <TextField source="id" />
            <NumberField
                source="Amount"
                options={{ style: "currency", currency: "USD" }}
            />
            <BooleanField source="Active" />
            <BooleanField source="IsPublic" />
            <BooleanField source="IsReoccurring" />
            <EditButton />
        </Datagrid>
    </ReactAdminList>
);
