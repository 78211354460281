import { TableRow, TableCell } from "@mui/material";
import { ResellerInventory } from "@switcherstudio/switcher-api-client";
import { Children, cloneElement } from "react";
import {
    Show as ReactAdminShow,
    useRecordContext,
    SimpleShowLayout,
    TextField,
    ChipField,
    ArrayField,
    SingleFieldList,
    NumberField,
    BooleanField,
    DateField,
    Datagrid,
    DatagridBody,
    EmailField,
    DatagridRowProps,
    TopToolbar,
    SelectField
} from "react-admin";
import { CopyAnyOrUnifiedCodeButton } from "../../components/buttons/CopyAvailableCodeButton";
import { CopyCodeButton } from "../../components/buttons/CopyCodeButton";
import { EditChildRedirectButton } from "../../components/buttons/EditChildRedirectButton";

export const Show: React.FC = () => (
    <ReactAdminShow actions={<Actions />}>
        <Layout />
    </ReactAdminShow>
);

export const Layout: React.FC = () => {
    const record = useRecordContext<ResellerInventory>();

    return (
        <SimpleShowLayout>
            {record &&
                record?.IsUnlimited &&
                record?.ResellerInventoryItems?.length! > 0 && (
                    <TextField
                        source="ResellerInventoryItems[0].CouponCode"
                        label="Coupon Code"
                    />
                )}
            <TextField source="Description" />
            <ChipField source="BillingProvider" />
            <TextField source="StripeCoupon" label="Stripe Coupon" />
            <ChipField source="Coupon.Duration" label="Duration" />
            <TextField
                source="Price.Name"
                label="Price"
                emptyText="For any price or plan"
            />
            {record.Features && record.Features?.length > 0 && (
                <ArrayField source="Features">
                    <SingleFieldList>
                        <ChipField source="Description" />
                    </SingleFieldList>
                </ArrayField>
            )}
            <NumberField
                source="TrialDays"
                label="Trial Days"
                textAlign="center"
                emptyText="No trial days"
            />
            <BooleanField source="IsUnlimited" label="Is Unlimited" />
            <NumberField
                source="ResellerInventoryRedeemedCount"
                label="Redeemed"
            />
            {!record.IsUnlimited && (
                <NumberField
                    source="ResellerInventoryTotalCount"
                    label="Total"
                />
            )}
            <DateField source="ValidUntil" emptyText="No expiration date" />
            <BooleanField source="IsForNewCustomersOnly" />
            <BooleanField source="IsOneRedemptionPerCustomer" />
            <BooleanField source="IsPaymentMethodRequired" />
            <BooleanField source="IsSilent" />
            <ArrayField
                source="ResellerInventoryItems"
                label="Reseller Inventory Items"
            >
                <Datagrid
                    bulkActionButtons={false}
                    body={
                        <DatagridBody
                            row={<DatagridRow parentRecord={record} />}
                        />
                    }
                >
                    <TextField source="CouponCode" />
                    <EmailField
                        source="RedeemedByUserEmail"
                        label="Redeemed By"
                    />
                    <SelectField
                        source="Status"
                        choices={[
                            { id: 0, name: "Available" },
                            { id: 1, name: "Sent" },
                            { id: 2, name: "Redeemed" },
                            { id: 3, name: "Invalidated" },
                            { id: 4, name: "Claimed" }
                        ]}
                    />
                    <DateField source="UpdatedAt" label="Updated At" />
                    <CopyCodeButton />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    );
};

export const DatagridRow: React.FC<
    DatagridRowProps & { parentRecord: ResellerInventory }
> = ({ record, parentRecord, resource, id, children }) => {
    if (parentRecord.IsUnlimited && record?.Status === 0) {
        return null;
    } else {
        return (
            <TableRow>
                {Children.map(children, (field: any) => (
                    <TableCell key={`${id}-${field.props.source}`}>
                        {cloneElement(field, {
                            record,
                            resource
                        })}
                    </TableCell>
                ))}
            </TableRow>
        );
    }
};

export const Actions: React.FC = () => {
    const record = useRecordContext();

    return (
        <TopToolbar>
            <EditChildRedirectButton
                childResource="Resellers-Inventories"
                parentProperty="ResellerId"
            />
            {record && (
                <CopyAnyOrUnifiedCodeButton
                    record={record as ResellerInventory}
                />
            )}
        </TopToolbar>
    );
};
