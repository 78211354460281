import { apiUrl } from ".";
import { renameKey } from "./helpers";
import { httpClient } from "./httpClient";

export const getList = async (resource: string) => {
    let url: string;
    const resources = resource.split("-");

    if (resources.length === 2) {
        const parentId = window.location.href.split("/").slice(-1).pop();
        if (parentId) {
            url = `${apiUrl}/api/${resources[0]}/${parentId}/${resources[1]}`;
        } else {
            url = `${apiUrl}/api/${resources.pop()}`;
        }
    } else {
        url = `${apiUrl}/api/${resource}`;
    }

    return httpClient(url).then(({ json }) => {
        json.forEach((object: any) => {
            renameKey(object, "Id", "id");
            renameKey(object, "UserId", "id");
        });

        return {
            data: formatGetListData(json),
            total: json.length
        };
    });
};

const formatGetListData = (data: any[]) => {
    data.forEach((item) => {
        if (item?.Amount) {
            item.Amount /= 100;
        }

        if (item?.RewardAmount) {
            item.RewardAmount /= 100;
        }
    });

    return data;
};
