import { CreateParams } from "react-admin";
import { apiUrl, dataProvider, hasRelationshipToUser } from ".";
import { client } from "../../api/client";
import { getQueryParam, stringifyIfDefined } from "../../helpers/queryParams";
import { renameKey } from "./helpers";
import { httpClient } from "./httpClient";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import { Asset, Extension } from "@switcherstudio/switcher-api-client";

export const create = async (resource: string, params: CreateParams<any>) => {
    let url,
        parentId: string = "";
    const resources = resource.split("-");

    if (resources.length === 1) {
        url = `${apiUrl}/api/${resources[0]}`;
    } else if (resources.length === 2) {
        parentId =
            params.meta?.parentId ??
            getQueryParam("parentId") ??
            params.data.id;
        if (parentId) {
            url = `${apiUrl}/api/${resources[0]}/${parentId}/${resources[1]}`;
        } else {
            url = `${apiUrl}/api/${resources.pop()}`;
        }
    }

    url += parseQueryStringParamsCreate(resource, params.data);

    const options = {
        method: "POST",
        body: JSON.stringify(
            await formatBodyCreate(resource, params.data, parentId)
        )
    };
    return httpClient(url, options).then(async ({ json }) => {
        renameKey(json, "Id", "id");

        if (hasRelationshipToUser.includes(resource)) {
            dataProvider.create(`${resource}-Users`, {
                data: { ...json, ...params.data }
            });
        }

        if (resource === "Icons") {
            const target = await fetch(json.Url).then((r) => r.blob());
            const blobServiceClient = new BlockBlobClient(
                json.Url,
                new AnonymousCredential()
            );
            const uploadBlockBlobOptions = {
                blobHTTPHeaders: { blobContentType: json.MimeType }
            };
            blobServiceClient.upload(
                target,
                target.size,
                uploadBlockBlobOptions
            );
        }

        return {
            data: json ?? { id: "1" }
        };
    });
};

const formatBodyCreate = async (
    resource: string,
    data: any,
    parentId: string | null = null
) => {
    switch (resource) {
        case "Resellers-Inventories":
            if (parentId) {
                return await formatBodyCreateResellersInventories(
                    data,
                    parentId
                );
            } else {
                throw new Error();
            }
        case "Extensions":
            return formatBodyCreateExtensions(data);
        case "Extensions-Icons":
            return formatBodyCreateExtensionsIcons(data);
        default:
            return data;
    }
};

const formatBodyCreateResellersInventories = async (
    data: any,
    resellerId: string
) => {
    renameKey(data, "Price", "PriceId");
    data.PriceId = data.PriceId === "" ? undefined : data.PriceId;
    data.StripeCoupon =
        data.StripeCoupon === "" ? undefined : data.StripeCoupon;

    if (data.Features.length > 0) {
        const features: any[] = [];

        for (const featureId of data.Features) {
            features.push(await client.features_GetFeature(featureId));
        }

        data.Features = features;
    }

    return {
        ...data,
        resellerId,
        TrialDays: data.HasTrialDays ? data.TrialDays : null
    };
};

const formatBodyCreateExtensions = (data: Extension) => {
    return {
        ...data,
        Icons: data.Icons?.map((i) => {
            return { Id: i };
        })
    };
};

const formatBodyCreateExtensionsIcons = (data: {
    Icons: string[];
}): Asset[] => {
    return data.Icons.map((i) => {
        return { Id: i };
    });
};

const parseQueryStringParamsCreate = (resource: string, data: any) => {
    switch (resource) {
        case "Resellers-Inventories":
            return parseQueryStringParamsCreateResellersInventories(data);
        default:
            return "";
    }
};

const parseQueryStringParamsCreateResellersInventories = (data: any) => {
    return (
        "?" +
        stringifyIfDefined({
            couponCode: data.CouponCode,
            quantity: data.HasQuantity ? data.Quantity : 0
        })
    );
};
