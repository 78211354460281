import * as React from "react";
import { Button, useRecordContext } from "react-admin";
import Add from "@mui/icons-material/Add";
import { stringify } from "query-string";
import { useCallback } from "react";

export interface CreateChildRedirectButtonProps {
    /** the resource to connect to - is inserted into the url - ex. "Resellers-Inventories" */
    childResource: string;
    /** label for button */
    label?: string;
}

/**
 * A button that will redirect a user to the create page of a child of the current record, passing the parent id through the query string
 */
export const CreateChildRedirectButton: React.FC<
    CreateChildRedirectButtonProps
> = ({ childResource, label }) => {
    const record = useRecordContext();

    const toChildCreate = useCallback(() => {
        window.location.href = `#/${childResource}/create?${stringify({
            parentId: record.id
        })}`;
    }, [record.id, childResource]);

    return (
        <Button
            onClick={toChildCreate}
            label={label ?? "Create Inventory"}
            style={{ margin: "1rem 0", padding: ".5rem 1rem" }}
        >
            <Add />
        </Button>
    );
};
