import {
    ResellerInventory,
    ResellerInventoryItemStatus
} from "@switcherstudio/switcher-api-client";

/**
 * @param record a ResellerInventory record
 * @returns either the first coupon code or the unified coupon code for the Reseller Inventory
 */
export const useGetAvailableCode = (
    record: ResellerInventory
): string | undefined => {
    return record?.ResellerInventoryItems?.filter(
        (i) => i.Status === ResellerInventoryItemStatus._0
    )
        .map((i) => i.CouponCode)
        .pop();
};
