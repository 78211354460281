import { useCallback } from "react";
import { Button, useRecordContext } from "react-admin";
import Download from "@mui/icons-material/Download";

export const DownloadBundleButton: React.FC = () => {
    const record = useRecordContext();

    const downloadBundle = useCallback(() => {
        if (record) {
            window.location = record?.Url;
        }
    }, [record]);

    return (
        <Button label="Download" onClick={downloadBundle}>
            <Download />
        </Button>
    );
};
