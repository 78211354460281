import { GetManyParams } from "react-admin";
import { apiUrl } from ".";
import { renameKey } from "./helpers";
import { httpClient } from "./httpClient";

export const getMany = async (resource: string, params: GetManyParams) => {
    const url = `${apiUrl}/api/Resellers/${params.ids[0]}/${resource}`;

    return httpClient(url).then(({ json }) => {
        json.forEach((object: any) => {
            renameKey(object, "Id", "id");
        });
        return {
            data: json
        };
    });
};
