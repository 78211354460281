import { Asset } from "@switcherstudio/switcher-api-client";
import { useCallback } from "react";
import {
    Create as ReactAdminCreate,
    SimpleForm,
    SelectArrayInput
} from "react-admin";
import { getQueryParam } from "../../helpers/queryParams";
import { useGetArray } from "../../hooks/useGetArray";

export const Create: React.FC = () => {
    const icons = useGetArray<Asset>("api/Icons");

    const redirect = useCallback(() => {
        return `Extensions/${getQueryParam("parentId")}/show`;
    }, []);

    return (
        <ReactAdminCreate redirect={redirect}>
            <SimpleForm>
                <SelectArrayInput
                    source="Icons"
                    choices={icons.data}
                    optionValue="Id"
                    optionText="Name"
                    disabled={icons.loading}
                />
            </SimpleForm>
        </ReactAdminCreate>
    );
};
