import {
    Show as ReactAdminShow,
    TabbedShowLayout,
    Tab,
    TextField,
    NumberField,
    ChipField,
    DateField,
    ReferenceManyField,
    Datagrid,
    EmailField,
    UrlField,
    BooleanField
} from "react-admin";
import { ShowRelationButton } from "../../components/buttons/ShowRelationButton";
import { ChipArrayField } from "../../components/fields/chips/ChipArrayField";

export const Show: React.FC = () => (
    <ReactAdminShow>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="Name" />
                <NumberField source="MaximumUsers" />
                <NumberField source="MaximumDestinations" />
                <NumberField source="VideoChatSecondsOverride" />
                <ChipField source="Status" />
                <ChipArrayField source="FeatureClaims" label="Feature Claims" />
                <DateField source="InsertedAt" label="Created At" />
            </Tab>
            <Tab label="Members">
                <ReferenceManyField
                    label="Organization Members"
                    reference="Organizations-Users"
                    source="id"
                    target="id"
                    fullWidth
                >
                    <Datagrid>
                        <EmailField source="Email" />
                        <ShowRelationButton
                            relatedResource="Users"
                            property="UserId"
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Videos">
                <ReferenceManyField
                    label="Videos"
                    reference="Organizations-Videos"
                    source="id"
                    target="id"
                    fullWidth
                >
                    <Datagrid>
                        <TextField source="Title" />
                        <EmailField source="Email" label="Creator" />
                        <DateField source="CreatedAt" label="Created At" />
                        <TextField source="Provider" />
                        <UrlField source="PermanentUrl" />
                        <BooleanField
                            source="UsedCrossposting"
                            label="Used Crossposting"
                        />
                        <TextField source="VideoId" label="Video Id" />
                        <TextField source="Privacy" />
                        <TextField source="VideoCategory" />
                        <ChipArrayField
                            source="ContentTags"
                            label="Content Tags"
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </ReactAdminShow>
);
