import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import { red } from "@mui/material/colors";

export const LightTheme = merge({}, defaultTheme, {
    palette: {
        primary: {
            light: "#437b8d",
            main: "#0c4f60",
            dark: "#002736"
        },
        secondary: {
            light: "#ff8850",
            main: "#ed5623",
            dark: "#b32000"
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "MONTSERRAT",
            "BlinkMacSystemFont",
            "Arial",
            "sans-serif"
        ].join(",")
    }
});
