import * as React from "react";
import { Button, useRecordContext } from "react-admin";
import Edit from "@mui/icons-material/Edit";
import { stringify } from "query-string";
import { useCallback } from "react";

export interface EditChildRedirectButtonProps {
    /** the resource to connect to - is inserted into the url - ex. "Resellers-Inventories" */
    childResource: string;
    /** the property of the current record that is the id of the parent object - ex. "ResellerId" */
    parentProperty: string;
    /** label for button */
    label?: string;
}

/**
 * Inside of a Datagrid or another component with record context this button will redirect a user to the create page of a child of the current record, passing the parent id through the query string
 */
export const EditChildRedirectButton: React.FC<
    EditChildRedirectButtonProps
> = ({ childResource, parentProperty, label }) => {
    const record = useRecordContext();

    const toChildEdit = useCallback(() => {
        window.location.href = `#/${childResource}/${record.id}?${stringify({
            parentId: record[parentProperty]
        })}`;
    }, [record, childResource, parentProperty]);

    return (
        <Button onClick={toChildEdit} label={label ?? "Edit"}>
            <Edit />
        </Button>
    );
};
