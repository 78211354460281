import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { DashboardShortcutCard } from "../components/cards/DashboardShortcutCard";
import { useCallback } from "react";
import styles from "./index.module.css";

const switcherResellerId = process.env.REACT_APP_SWITCHER_STUDIO_RESELLER_ID;

export const Dashboard: React.FC = () => {
    const redirect = useCallback((url: string, condition: boolean = true) => {
        if (condition) {
            window.location.href = url;
        }
    }, []);

    return (
        <>
            <Card>
                <CardHeader title="Welcome to the Switcher Studio" />
                <CardContent>Don't be sad, just use SADR</CardContent>
            </Card>
            <div className={styles["shortcuts"]}>
                <DashboardShortcutCard
                    header="Create Switcher Coupon"
                    onClick={() =>
                        redirect(
                            `#/Resellers-Inventories/create?parentId=${switcherResellerId}`,
                            !!switcherResellerId
                        )
                    }
                    body="Go directly to Switcher Studio reseller and create a new Inventory."
                />
                <DashboardShortcutCard
                    header="Upload Extension Icon"
                    onClick={() => redirect(`#/Icons/create`)}
                    body="Upload an asset to be used as an Extension Icon."
                />
            </div>
        </>
    );
};
