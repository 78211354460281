import * as React from "react";
import { useCallback, useState } from "react";
import {
    List as ReactAdminList,
    Datagrid,
    EmailField,
    DateField,
    TopToolbar,
    Button
} from "react-admin";
import { BulkFulfillDeletionRequestButton } from "../../components/buttons/BulkFulfillDeletionRequestButton";
import { FulfillDeletionRequestButton } from "../../components/buttons/FulfillDeletionRequestButton";
import { isBefore, subDays } from "date-fns";

export interface ListActionsProps {
    graceDisabled: boolean;
    setGraceDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const gracePeriod = parseInt(
    process.env.REACT_APP_DELETION_REQUEST_GRACE_PERIOD ?? "7"
);

export const List: React.FC = () => {
    const [graceDisabled, setGraceDisabled] = useState<boolean>(false);

    const isInGracePeriod = useCallback(
        (record) => {
            if (record.InsertedAt) {
                return (
                    graceDisabled ||
                    isBefore(
                        new Date(record.InsertedAt),
                        subDays(new Date(), gracePeriod)
                    )
                );
            } else {
                return false;
            }
        },
        [graceDisabled]
    );

    return (
        <ReactAdminList
            title="Pending Requests for Account Deletion"
            sort={{ field: "InsertedAt", order: "DESC" }}
            actions={
                <ListActions
                    graceDisabled={graceDisabled}
                    setGraceDisabled={setGraceDisabled}
                />
            }
            bulkActionButtons={<BulkActionButtons />}
        >
            <Datagrid rowClick="edit" isRowSelectable={isInGracePeriod}>
                <EmailField source="Email" />
                <DateField source="InsertedAt" label="Date Requested" />
                <FulfillDeletionRequestButton
                    graceDisabled={graceDisabled}
                    gracePeriod={gracePeriod}
                />
            </Datagrid>
        </ReactAdminList>
    );
};

export const ListActions: React.FC<ListActionsProps> = ({
    graceDisabled,
    setGraceDisabled
}) => (
    <TopToolbar>
        <Button
            label={`${
                graceDisabled
                    ? "Grace Period is Disabled"
                    : "Disable Grace Period"
            } `}
            onClick={() => setGraceDisabled((v) => !v)}
            variant={graceDisabled ? "contained" : "text"}
        />
    </TopToolbar>
);

const BulkActionButtons: React.FC = ({selectedIds}: any) => (
    <>
        <BulkFulfillDeletionRequestButton selectedIds={selectedIds} />
    </>
);
