import React from "react";
import { Admin, Resource } from "react-admin";
import { LightTheme } from "./themes/LightTheme";
import { authProvider } from "./providers/authProvider";
import { dataProvider } from "./providers/dataProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./translations/en";

import { Dashboard } from "./pages/Dashboard";
import { NotFoundPage } from "./pages/NotFoundPage";

import User from "@mui/icons-material/Person";
import Reseller from "@mui/icons-material/MonetizationOn";
import Delete from "@mui/icons-material/Delete";
import Organization from "@mui/icons-material/Group";
import Domain from "@mui/icons-material/Domain";
import Bundle from "@mui/icons-material/CloudCircle";
import { UsersList, UsersEdit, UsersShow } from "./views/users";
import Price from "@mui/icons-material/PriceChange";
import Extension from "@mui/icons-material/Extension";

import {
    ResellersList,
    ResellersEdit,
    ResellersCreate,
    ResellersShow
} from "./views/resellers";

import {
    ResellerInventoriesCreate,
    ResellerInventoriesEdit,
    ResellerInventoriesShow
} from "./views/reseller-inventories";

import { ResellerUsersCreate } from "./views/reseller-users";

import { DeletionRequestsList } from "./views/deletion-requests";

import {
    AllowedDomainList,
    AllowedDomainEdit,
    AllowedDomainCreate
} from "./views/allowed-domains";

import {
    ReactBundlesList,
    ReactBundlesEdit,
    ReactBundlesShow
} from "./views/react-bundles";

import {
    OrganizationsEdit,
    OrganizationsList,
    OrganizationsShow
} from "./views/organizations";

import {
    StripePriceEdit,
    StripePriceList,
    StripePriceShow
} from "./views/stripe-prices";

import {
    ExtensionsCreate,
    ExtensionsEdit,
    ExtensionsList,
    ExtensionsShow
} from "./views/extensions";
import { ExtensionsIconsCreate } from "./views/extensions-icons";
import { IconsCreate } from "./views/icons";
import { UserClaimForm } from "./views/user-claims";

const App = () => (
    <Admin
        title="SADR"
        dashboard={Dashboard}
        catchAll={NotFoundPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={polyglotI18nProvider(() => englishMessages, "en", {
            allowMissing: true,
            onMissingKey: (key: any, _: any, __: any) => key
        })}
        theme={LightTheme}
    >
        {process.env.REACT_APP_ENABLE_USER_LIST ? (
            <Resource
                name="Users"
                list={UsersList}
                edit={UsersEdit}
                show={UsersShow}
                icon={User}
                options={{ label: "Users" }}
            />
        ) : (
            <></>
        )}
        <Resource
            name="Resellers"
            list={ResellersList}
            edit={ResellersEdit}
            create={ResellersCreate}
            show={ResellersShow}
            icon={Reseller}
        />
        <Resource
            name="Resellers-Inventories"
            show={ResellerInventoriesShow}
            edit={ResellerInventoriesEdit}
            create={ResellerInventoriesCreate}
        />
        <Resource name="Resellers-Users" create={ResellerUsersCreate} />
        <Resource
            name="DeletionRequests"
            list={DeletionRequestsList}
            options={{ label: "Deletion Requests" }}
            icon={Delete}
        />
        <Resource
            name="Organizations"
            list={OrganizationsList}
            show={OrganizationsShow}
            edit={OrganizationsEdit}
            icon={Organization}
        />
        <Resource
            name="AllowedDomains"
            options={{ label: "Allowed Domains" }}
            list={AllowedDomainList}
            edit={AllowedDomainEdit}
            create={AllowedDomainCreate}
            icon={Domain}
        />
        <Resource
            name="ReactBundles"
            options={{ label: "React Bundles" }}
            list={ReactBundlesList}
            edit={ReactBundlesEdit}
            show={ReactBundlesShow}
            icon={Bundle}
        />
        <Resource
            name="Extensions"
            create={ExtensionsCreate}
            list={ExtensionsList}
            edit={ExtensionsEdit}
            show={ExtensionsShow}
            icon={Extension}
        />
        <Resource name="Extensions-Icons" create={ExtensionsIconsCreate} />
        <Resource name="Icons" create={IconsCreate} />
        <Resource
            name="StripePrices"
            options={{ label: "Stripe Prices" }}
            list={StripePriceList}
            edit={StripePriceEdit}
            show={StripePriceShow}
            icon={Price}
        />
        <Resource name="Organizations-Users" />
        <Resource name="Organizations-Videos" />

        {/* This is a custom control */}
        <Resource
            name="AspNetUserClaims"
            options={{ label: "User Claims" }}
            list={UserClaimForm}
        />
    </Admin>
);

export default App;
