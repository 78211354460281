import {
    List as ReactAdminList,
    Datagrid,
    TextField,
    ShowButton,
    BooleanField,
    DateField,
    EditButton
} from "react-admin";
import { DownloadBundleButton } from "../../components/buttons/DownloadBundleButton";

export const List: React.FC = () => (
    <ReactAdminList>
        <Datagrid rowClick="edit">
            <TextField source="Version" />
            <TextField source="MinApiVersion" />
            <TextField source="MaxApiVersion" emptyText="Current" />
            <BooleanField source="IsTestFlightOnly" />
            <DateField source="InsertedAt" label="Uploaded At" />
            <ShowButton />
            <EditButton />
            <DownloadBundleButton />
        </Datagrid>
    </ReactAdminList>
);
