import { DeleteManyParams } from "react-admin";
import { apiUrl } from ".";
import { httpClient } from "./httpClient";

export const deleteMany = async (
    resource: string,
    { ids }: DeleteManyParams
) => {
    try {
        Promise.allSettled(
            ids.map(async (id) => {
                const url = `${apiUrl}/api/${resource}/${id}`;

                return httpClient(url, { method: "DELETE" }).then(
                    ({ json }) => {
                        return {
                            data: json
                        };
                    }
                );
            })
        );

        return Promise.resolve({ data: ids });
    } catch {
        return Promise.reject("deleteMany was not successful");
    }
};
