import { Feature } from "@switcherstudio/switcher-api-client";
import {
    Edit as ReactAdminEdit,
    SelectInput,
    SimpleForm,
    TextInput,
    ValidateForm
} from "react-admin";
import { validate } from ".";
import { CreateChildRedirectButton } from "../../components/buttons/CreateChildRedirectButton";
import { ExtensionIconField } from "../../components/fields/icon/ExtensionIconField";
import { useGetArray } from "../../hooks/useGetArray";

export const Edit: React.FC = () => {
    const features = useGetArray<Feature>("api/Features");

    return (
        <ReactAdminEdit>
            <SimpleForm validate={validate as ValidateForm}>
                <TextInput source="Name" />
                <TextInput source="ComponentName" />
                <TextInput source="MinBundleVersion" />
                <TextInput source="MaxBundleVersion" />
                <SelectInput
                    source="RequiredClaim"
                    choices={features.data}
                    emptyText={"None"}
                    optionValue="ClaimId"
                    optionText="Description"
                    disabled={features.loading}
                    defaultValue={undefined}
                    fullWidth
                />
                <ExtensionIconField source="Icons" removable />
                <CreateChildRedirectButton
                    childResource="Extensions-Icons"
                    label="Add Icon"
                />
            </SimpleForm>
        </ReactAdminEdit>
    );
};
