import { Create } from "./Create";
import { List } from "./List";
import { Edit } from "./Edit";
import { Show } from "./Show";
import { Extension } from "@switcherstudio/switcher-api-client";
import { isVersionNumber } from "../../helpers/validation";

export { Create as ExtensionsCreate };
export { List as ExtensionsList };
export { Edit as ExtensionsEdit };
export { Show as ExtensionsShow };

export const validate = (values: Extension) => ({
    ...(!values.Name && { Name: "Name is required" }),
    ...(!values.ComponentName && {
        ComponentName: "Component name is required"
    }),
    ...(!values.MinBundleVersion && {
        MinBundleVersion: "MinBundle Version is required"
    }),
    ...(!isVersionNumber(values.MinBundleVersion) && {
        MinBundleVersion: "Must be a valid version number (1.0.0)"
    }),
    ...(values.MaxBundleVersion &&
        !isVersionNumber(values.MaxBundleVersion) && {
            MaxBundleVersion: "Must be a valid version number (1.0.0)"
        })
});
