import {
    Create as ReactAdminCreate,
    FileField,
    FileInput,
    SimpleForm
} from "react-admin";

export const Create: React.FC = () => {
    return (
        <ReactAdminCreate title="Upload Icon" redirect={() => "/"}>
            <SimpleForm>
                <FileInput
                    source="icon"
                    label="Icons"
                    accept="image/svg+xml"
                    placeholder={<p>Drop your file here</p>}
                    multiple={false}
                >
                    <FileField source="url" title="title" />
                </FileInput>
            </SimpleForm>
        </ReactAdminCreate>
    );
};
