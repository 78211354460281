import {
    Coupon,
    Price,
    Feature,
    ResellerInventory,
    ResellerInventoryBillingProvider,
    StripePrice
} from "@switcherstudio/switcher-api-client";
import { useState, useCallback, MouseEventHandler } from "react";
import {
    Create as ReactAdminCreate,
    SimpleForm,
    TextInput,
    BooleanInput,
    DateInput,
    SelectInput,
    SelectArrayInput,
    FormDataConsumer,
    SaveButton,
    useRedirect,
    Toolbar as ReactAdminToolbar,
    useCreate,
    RadioButtonGroupInput,
    useNotify
} from "react-admin";
import { getQueryParam } from "../../helpers/queryParams";
import { useGetArray } from "../../hooks/useGetArray";
import styles from "./index.module.css";
import { displayAmount } from "../../helpers/stripe";

export type ResellerInventoryCreateForm = ResellerInventory & {
    CouponCode?: string;
    Quantity?: number;
};

export const Create: React.FC = () => {
    const [hasQuantity, setHasQuantity] = useState<boolean>(false);
    const [hasTrialDays, setHasTrialDays] = useState<boolean>(false);
    const [hasExpirationDate, setHasExpirationDate] = useState<boolean>(false);
    const [data, setData] = useState();

    const coupons = useGetArray<Coupon>("api/Stripe/Coupons");
    const prices = useGetArray<Price>("api/Prices");
    const features = useGetArray<Feature>("api/Features");

    const redirect = useCallback((_, id) => {
        return `/Resellers-Inventories/${id}/show?parentId=${getQueryParam(
            "parentId"
        )}`;
    }, []);

    const validate = useCallback(
        (values: any) => ({
            ...(!values.Description && {
                Description: "Description is required"
            }),
            ...(!values.CouponCode &&
                !hasQuantity && {
                    CouponCode: "Unlimited coupons must be given a code"
                }),
            ...(hasQuantity &&
                !values.Quantity && {
                    Quantity: "Limited coupons must have a quantity"
                }),
            ...(values.Quantity &&
                isNaN(parseInt(values?.Quantity)) && {
                    Quantity: "Quantity must be a number"
                }),
            ...(values.Quantity &&
                parseInt(values?.Quantity) <= 0 && {
                    Quantity: "Quantity must be greater than zero"
                }),
            ...(hasTrialDays &&
                !values.TrialDays && {
                    TrialDays:
                        "Coupons with trial days must be given number of days for trial"
                }),
            ...(values.TrialDays &&
                isNaN(parseInt(values?.TrialDays)) && {
                    TrialDays: "Trial days must be a number"
                }),
            ...(values.TrialDays &&
                parseInt(values?.TrialDays) <= -1 && {
                    TrialDays: "Trial days must be zero or greater"
                }),
            ...(hasExpirationDate &&
                !values.ValidUntil && {
                    ValidUntil:
                        "Coupons with expiration date must be given a date to expire"
                }),
            ...(Date.parse(values.ValidUntil ?? "") < Date.now() && {
                ValidUntil: "Expiration date must be in the future"
            }),
            ...(values.BillingProvider !==
                ResellerInventoryBillingProvider.Stripe &&
                values.StripeCoupon && {
                    StripeCoupon:
                        "Stripe Coupons can only be applied to Inventories with Stripe as the billing provider"
                }),
            ...(values.BillingProvider !==
                ResellerInventoryBillingProvider.Stripe &&
                values.Price && {
                    Price: "Stripe Prices can only be applied to Inventories with Stripe as the billing provider"
                })
        }),
        [hasQuantity, hasExpirationDate, hasTrialDays]
    );

    return (
        <ReactAdminCreate>
            <SimpleForm
                redirect={redirect}
                toolbar={<Toolbar data={data} validate={validate} />}
            >
                <FormDataConsumer>
                    {({ formData }) => {
                        setData(formData);
                        return <></>;
                    }}
                </FormDataConsumer>
                <TextInput source="CouponCode" fullWidth />
                <TextInput source="Description" multiline fullWidth />
                <RadioButtonGroupInput
                    source="BillingProvider"
                    choices={[
                        { id: "Stripe", name: "Stripe" },
                        { id: "Shopify", name: "Shopify" }
                    ]}
                    defaultValue={0}
                />

                <BooleanInput
                    source="IsPaymentMethodRequired"
                    label="Payment method required"
                    defaultChecked
                />
                <BooleanInput
                    source="IsOneRedemptionPerCustomer"
                    label="One redemption per customer"
                />
                <BooleanInput
                    source="IsForNewCustomersOnly"
                    label="For new customers only"
                />
                <BooleanInput
                    source="IsSilent"
                    label="Bypass notifications for user (Is Silent)"
                />

                <div className={styles["conditional-input-wrapper"]}>
                    <BooleanInput
                        source="HasQuantity"
                        label="Has limited quantity"
                        options={{ checked: hasQuantity }}
                        onClick={() => setHasQuantity((v) => !v)}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.HasQuantity && (
                                <TextInput
                                    source="Quantity"
                                    {...rest}
                                    fullWidth
                                />
                            )
                        }
                    </FormDataConsumer>
                </div>

                <div className={styles["conditional-input-wrapper"]}>
                    <BooleanInput
                        source="HasTrialDays"
                        options={{ checked: hasTrialDays }}
                        onClick={() => setHasTrialDays((v) => !v)}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.HasTrialDays && (
                                <TextInput
                                    source="TrialDays"
                                    {...rest}
                                    fullWidth
                                />
                            )
                        }
                    </FormDataConsumer>
                </div>

                <div className={styles["conditional-input-wrapper"]}>
                    <BooleanInput
                        source="HasExpirationDate"
                        options={{ checked: hasExpirationDate }}
                        onClick={() => setHasExpirationDate((v) => !v)}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.HasExpirationDate && (
                                <DateInput
                                    source="ValidUntil"
                                    fullWidth
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                </div>

                <SelectInput
                    source="StripeCoupon"
                    choices={coupons.data}
                    optionText={(record: any) => record.name ?? record.id}
                    disabled={coupons.loading}
                    resettable
                    emptyText="NONE"
                    defaultValue={undefined}
                    emptyValue={null}
                    fullWidth
                />
                <SelectInput
                    source="Price"
                    label="Plan"
                    choices={prices.data}
                    optionValue="Id"
                    optionText={(record: StripePrice) =>
                        `${record.Name ?? record.Product?.Name} - ${displayAmount(record.Amount ?? 0, {signed: true})} (${record.RecurringInterval ?? "one-time" }) - ${record.Id}`
                    }
                    disabled={prices.loading}
                    resettable
                    emptyText="NONE"
                    defaultValue={undefined}
                    emptyValue={undefined}
                    fullWidth
                />
                <SelectArrayInput
                    source="Features"
                    choices={features.data}
                    optionValue="Id"
                    optionText="Description"
                    disabled={features.loading}
                    defaultValue={[]}
                    fullWidth
                />
            </SimpleForm>
        </ReactAdminCreate>
    );
};

export const Toolbar: React.FC<{
    data: any;
    validate: (values: ResellerInventoryCreateForm) => {
        ValidUntil?: string | undefined;
        TrialDays?: string | undefined;
        Quantity?: string | undefined;
        CouponCode?: string | undefined;
        Description?: string | undefined;
    };
}> = ({ data, validate }) => {
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleSave = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            e.preventDefault();

            const errors = Object.values(validate(data));

            if (errors.length === 0) {
                data.Quantity = parseInt(data.Quantity);
                data.TrialDays = parseInt(data.TrialDays);

                create(
                    "Resellers-Inventories",
                    {
                        data,
                        meta: { parentId: getQueryParam("parentId") }
                    },
                    {
                        onSuccess: (data) =>
                            redirect(
                                `/Resellers-Inventories/${
                                    data.id
                                }/show?parentId=${getQueryParam("parentId")}`
                            )
                    }
                );
            } else {
                notify(errors[0], { type: "warning" });
            }
        },
        [create, data, redirect, validate, notify]
    );

    return (
        <ReactAdminToolbar
            style={{ display: "flex", justifyContent: "space-between" }}
        >
            <SaveButton onClick={handleSave} />
        </ReactAdminToolbar>
    );
};
