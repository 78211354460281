import {
    List as ReactAdminList,
    Datagrid,
    EmailField,
    BooleanField,
    ShowButton,
    EditButton
} from "react-admin";

export const List: React.FC = () => (
    <ReactAdminList>
        <Datagrid rowClick="show">
            <EmailField source="Email" />
            <BooleanField
                source="IsEmailConfirmed"
                label="Email Is Confirmed"
            />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </ReactAdminList>
);
