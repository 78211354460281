import { stringify } from "query-string";
import { MouseEventHandler, useCallback, useState } from "react";
import {
    Edit as ReactAdminEdit,
    useRecordContext,
    SimpleForm,
    TextInput,
    NumberInput,
    minValue,
    DateInput,
    BooleanInput,
    Toolbar as ReactAdminToolbar,
    SaveButton,
    DeleteButton,
    TopToolbar,
    number,
    useUpdate,
    FormDataConsumer,
    useRedirect,
    useNotify
} from "react-admin";
import { ShowChildButton } from "../../components/buttons/ShowChildButton";
import { getQueryParam } from "../../helpers/queryParams";
import { ResellerInventoryCreateForm } from "./Create";

export const Edit: React.FC = () => (
    <ReactAdminEdit actions={<Actions />}>
        <Form />
    </ReactAdminEdit>
);

export const Form: React.FC = () => {
    const record = useRecordContext();
    const [data, setData] = useState();

    const validate = useCallback(
        (values: any) => ({
            ...(!values.Description && {
                Description: "Description is required"
            }),
            ...(values.Quantity &&
                isNaN(parseInt(values?.Quantity)) && {
                    Quantity: "Quantity must be a number"
                }),
            ...(values.Quantity &&
                parseInt(values?.Quantity) <= 0 && {
                    Quantity: "Quantity must be greater than zero"
                }),
            ...(values.TrialDays &&
                isNaN(parseInt(values?.TrialDays)) && {
                    TrialDays: "Trial days must be a number"
                }),
            ...(values.TrialDays &&
                parseInt(values?.TrialDays) <= -1 && {
                    TrialDays: "Trial days must be zero or greater"
                }),
            ...(Date.parse(values.ValidUntil ?? "") < Date.now() && {
                ValidUntil: "Expiration date must be in the future"
            })
        }),
        []
    );

    return (
        <SimpleForm
            defaultValues={record}
            toolbar={<Toolbar data={data} validate={validate} />}
            redirect={(_: any, id: string, data: any) =>
                `/Resellers-Inventories/${id}/show?${stringify({
                    parentId: data.ResellerId
                })}`
            }
        >
            <FormDataConsumer>
                {({ formData }) => {
                    setData(formData);
                    return <></>;
                }}
            </FormDataConsumer>
            <TextInput source="Description" multiline fullWidth />
            <NumberInput source="TrialDays" fullWidth />
            {!record.IsUnlimited && (
                <NumberInput
                    source="ResellerInventoryTotalCount"
                    label="Quantity"
                    validate={[
                        number(),
                        minValue(
                            record.ResellerInventoryTotalCount,
                            "Items may only be added, not removed"
                        )
                    ]}
                    fullWidth
                />
            )}
            <DateInput source="ValidUntil" fullWidth />
            <BooleanInput source="IsForNewCustomersOnly" />
            <BooleanInput source="IsOneRedemptionPerCustomer" />
            <BooleanInput source="IsPaymentMethodRequired" />
            <BooleanInput
                source="IsSilent"
                label="Bypass notifications for user (Is Silent)"
            />
        </SimpleForm>
    );
};

export const Toolbar: React.FC<{
    data: any;
    validate: (values: ResellerInventoryCreateForm) => {
        ValidUntil?: string | undefined;
        TrialDays?: string | undefined;
        Quantity?: string | undefined;
        CouponCode?: string | undefined;
        Description?: string | undefined;
    };
}> = ({ data, validate }) => {
    const record = useRecordContext();
    const notify = useNotify();
    const [update] = useUpdate();
    const redirect = useRedirect();

    const handleSave = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            e.preventDefault();
            const errors = Object.values(validate(data));

            if (errors.length === 0) {
                update(
                    "Resellers-Inventories",
                    {
                        id: record.id,
                        data,
                        previousData: record,
                        meta: { parentId: getQueryParam("parentId") }
                    },
                    {
                        onSuccess: () =>
                            redirect(
                                `/Resellers-Inventories/${
                                    record?.id
                                }/show?parentId=${getQueryParam("parentId")}`
                            )
                    }
                );
            } else {
                notify(errors[0], { type: "warning" });
            }
        },
        [validate, data, update, record, redirect, notify]
    );

    return (
        <ReactAdminToolbar
            style={{ display: "flex", justifyContent: "space-between" }}
        >
            <SaveButton alwaysEnable onClick={handleSave} />
            <DeleteButton
                redirect={`/Resellers/${getQueryParam("parentId")}/show`}
                mutationMode="pessimistic"
            />
        </ReactAdminToolbar>
    );
};

export const Actions: React.FC = () => (
    <TopToolbar>
        <ShowChildButton
            childResource="Resellers-Inventories"
            parentProperty="ResellerId"
        />
    </TopToolbar>
);
