import {
    Show as ReactAdminShow,
    SimpleShowLayout,
    TextField
} from "react-admin";
import { ExtensionIconField } from "../../components/fields/icon/ExtensionIconField";

export const Show: React.FC = () => (
    <ReactAdminShow>
        <SimpleShowLayout>
            <TextField source="Name" />
            <TextField source="ComponentName" />
            <TextField source="MinBundleVersion" />
            <TextField source="MaxBundleVersion" emptyText="Current" />
            <TextField source="RequiredClaims" emptyText="None" />
            <ExtensionIconField source="Icons" />
        </SimpleShowLayout>
    </ReactAdminShow>
);
