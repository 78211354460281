import * as React from "react";
import {
    List as ReactAdminList,
    Datagrid,
    TextField,
    DeleteButton,
    ShowButton
} from "react-admin";
import { CreateChildRedirectButton } from "../../components/buttons/CreateChildRedirectButton";
import { ExtensionIconField } from "../../components/fields/icon/ExtensionIconField";

export const List: React.FC = () => (
    <ReactAdminList>
        <Datagrid>
            <TextField source="Name" />
            <TextField source="ComponentName" />
            <TextField source="MinBundleVersion" />
            <TextField source="MaxBundleVersion" emptyText="Current" />
            <TextField source="RequiredClaim" emptyText="None" />
            <ExtensionIconField source="Icons" />
            <ShowButton />
            <DeleteButton />
            <CreateChildRedirectButton
                childResource="Extensions-Icons"
                label="Add Icon"
            />
        </Datagrid>
    </ReactAdminList>
);
