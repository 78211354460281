import {
    Show as ReactAdminShow,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    ReferenceManyField,
    Datagrid,
    ChipField,
    NumberField,
    BooleanField,
    EmailField
} from "react-admin";
import { CreateChildRedirectButton } from "../../components/buttons/CreateChildRedirectButton";
import { DeleteChildButton } from "../../components/buttons/DeleteChildButton";
import { EditChildRedirectButton } from "../../components/buttons/EditChildRedirectButton";
import { ShowChildButton } from "../../components/buttons/ShowChildButton";
import { ShowRelationButton } from "../../components/buttons/ShowRelationButton";

export const Show: React.FC = () => (
    <ReactAdminShow>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="Name" />
                <DateField source="InsertedAt" />
                <DateField source="UpdatedAt" />
                <ReferenceManyField
                    label="Inventories"
                    source="id"
                    target="id"
                    reference="Resellers-Inventories"
                    fullWidth
                >
                    <Datagrid bulkActionButtons={<></>}>
                        <TextField source="Description" />
                        <ChipField source="BillingProvider" />
                        <TextField source="UnifiedCouponCode" label="Code" />
                        <TextField source="StripeCoupon" label="StripeCoupon" />
                        <ChipField source="Coupon.Duration" label="Duration" />
                        <NumberField
                            source="TrialDays"
                            label="Trial Days"
                            textAlign="center"
                        />
                        <BooleanField
                            source="IsUnlimited"
                            label="Is Unlimited"
                        />
                        <BooleanField source="IsSilent" label="Is Silent" />
                        <NumberField
                            source="ResellerInventoryTotalCount"
                            label="Total"
                        />
                        <NumberField
                            source="ResellerInventoryRedeemedCount"
                            label="Redeemed"
                        />
                        <DateField source="ValidUntil" />
                        <ShowChildButton
                            childResource="Resellers-Inventories"
                            parentProperty="ResellerId"
                        />
                        <EditChildRedirectButton
                            childResource="Resellers-Inventories"
                            parentProperty="ResellerId"
                        />
                    </Datagrid>
                </ReferenceManyField>
                <CreateChildRedirectButton
                    childResource="Resellers-Inventories"
                    label="Create Inventory"
                />
            </Tab>
            <Tab label="Users">
                <ReferenceManyField
                    label="Users"
                    source="id"
                    target="id"
                    reference="Resellers-Users"
                    fullWidth
                >
                    <Datagrid>
                        <EmailField source="Email" />
                        <ShowRelationButton
                            relatedResource="Users"
                            property="UserId"
                        />
                        <DeleteChildButton
                            childResource="Resellers-Users"
                            childProperty="UserId"
                            parentProperty="ResellerId"
                        />
                    </Datagrid>
                </ReferenceManyField>
                <CreateChildRedirectButton
                    childResource="Resellers-Users"
                    label="Add User"
                />
            </Tab>
        </TabbedShowLayout>
    </ReactAdminShow>
);
