import axios from "axios";
import { stringify } from "query-string";

interface loginParameters {
    username: string;
    password: string;
}

interface checkErrorParameters {
    status: number;
}

const apiUrl = process.env.REACT_APP_API_URL;

export const authProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }: loginParameters) => {
        return axios({
            url: `${apiUrl}/Token`,
            method: "POST",
            data: stringify({
                grant_type: "password",
                username,
                password,
                client_id: "SilverSunnDashboard"
            }),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then((response) => {
                const roles = response.data.roles.split(",");
                if (!roles.includes("Admin")) {
                    return new Error("You are not an admin, punk.");
                }

                localStorage.setItem("usrtikt", JSON.stringify(response.data));
                return response.data;
            })
            .catch((err) => {
                if (err instanceof Error) {
                    throw err;
                }
                throw new Error(`${err}`);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem("usrtikt");
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }: checkErrorParameters) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem("usrtikt");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem("usrtikt")
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve()
};
