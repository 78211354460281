import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";
import { useState } from "react";

export interface ChipArrayFieldProps {
    /** The property of the desired array to be shown. To access a property within an object in an array use a "." between the two keys - ex. "Features.Description" */
    source: string;
    /** Text for a label. Defaults to source. */
    label?: string;
}

/**
 * Allows an array of strings to be displayed as chips
 */
export const ChipArrayField: React.FC<ChipArrayFieldProps> = ({
    source,
    label
}) => {
    const record = useRecordContext();

    const [sources] = useState<string[]>(source.split("."));

    if (record[sources[0]]?.length > 0) {
        return (
            <>
                <span
                    className="MuiFormLabel-root"
                    style={{
                        fontSize: "12px"
                    }}
                >
                    {label ?? source}
                </span>
                <br />
                <div style={{ margin: ".5rem 0" }}>
                    {record[sources[0]].map((chip: any, key: number) => (
                        <span
                            className="RaLabeled-value-63"
                            style={{ marginRight: ".5rem" }}
                            key={key}
                        >
                            <Chip
                                label={sources[1] ? chip[sources[1]] : chip}
                            />
                        </span>
                    ))}
                </div>
            </>
        );
    } else {
        return null;
    }
};
