import { GetOneParams } from "react-admin";
import { apiUrl } from ".";
import { getQueryParam } from "../../helpers/queryParams";
import { renameKey } from "./helpers";
import { httpClient } from "./httpClient";

export const getOne = async (resource: string, params: GetOneParams) => {
    let url: string;
    const resources = resource.split("-");

    if (resources.length === 1) {
        url = `${apiUrl}/api/${resources[0]}/${params.id}`;
    } else if (resources.length === 2) {
        const parentId = getQueryParam("parentId");
        if (parentId) {
            url = `${apiUrl}/api/${resources[0]}/${parentId}/${resources[1]}/${params.id}`; // if there is a parentId in the queryString, then should have a parent relationship
        } else {
            url = `${apiUrl}/api/${resources.pop()}/${params.id}`; // if not, then the last resource in the list is used in path
        }
    } else {
        url = "";
    }

    return httpClient(url).then(({ json }) => {
        renameKey(json, "Id", "id");
        renameKey(json, "UserId", "id"); // this changes the primary key of "UserId" to "id", but this may cause problems if there is a foreign key called "UserId"

        switch (resource) {
            case "Resellers-Inventories":
                json.ResellerInventoryItems.sort(
                    (a: any, b: any) =>
                        new Date(b.UpdatedAt).getTime() -
                        new Date(a.UpdatedAt).getTime()
                );
                break;
            default:
                break;
        }

        return {
            data: formatGetOneData(json)
        };
    });
};

const formatGetOneData = (item: any) => {
    if (item.Amount) {
        item.Amount /= 100;
    }

    if (item.RewardAmount) {
        item.RewardAmount /= 100;
    }

    return item;
};
