import { Create as ReactAdminCreate, SimpleForm, TextInput } from "react-admin";

export const Create: React.FC = () => (
    <ReactAdminCreate>
        <SimpleForm>
            <TextInput source="Name" />
            <TextInput source="UserEmail" label="User Email" />
        </SimpleForm>
    </ReactAdminCreate>
);
