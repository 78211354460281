import * as React from "react";
import { Button, useRecordContext } from "react-admin";
import Show from "@mui/icons-material/RemoveRedEye";
import { stringify } from "query-string";
import { useCallback } from "react";

export interface ShowChildButtonProps {
    /** the resource to connect to - is inserted into the url - ex. "Resellers-Inventories" */
    childResource: string;
    /** the property of the current record that relates to the record to show - ex. "ResellerId" */
    parentProperty: string;
}

/**
 * Inside of a Datagrid or another component with record context this button will redirect to the Show page of another record that is the child of the current record
 * Will pass the id of the parent in query string which will then be queried in the dataProvider method "getOne"
 */
export const ShowChildButton: React.FC<ShowChildButtonProps> = ({
    childResource,
    parentProperty
}) => {
    const record = useRecordContext();

    const toInventoryShow = useCallback(() => {
        window.location.href = `#/${childResource}/${
            record.id
        }/show?${stringify({
            parentId: record[parentProperty]
        })}`;
    }, [record, childResource, parentProperty]);

    return (
        <Button onClick={toInventoryShow} label="Show">
            <Show />
        </Button>
    );
};
