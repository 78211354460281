import { useState, useCallback } from "react";
import {
    Edit as ReactAdminEdit,
    SimpleForm,
    TextInput,
    useNotify,
    TopToolbar,
    Button,
    ShowButton
} from "react-admin";
import { client } from "../../api/client";
import Reset from "@mui/icons-material/Restore";

export const Edit: React.FC = () => (
    <ReactAdminEdit actions={<Actions />}>
        <SimpleForm>
            <TextInput source="Email" />
        </SimpleForm>
    </ReactAdminEdit>
);

export const Actions: React.FC<any> = ({ data }) => {
    const notify = useNotify();
    const [confirmationLink, setConfirmationLink] = useState<string>("");

    const resetPassword = useCallback(async () => {
        if (data) {
            const { Message: message, Url: url } =
                await client.account_ForgotPasswordAdmin({ Email: data.Email });
            if (message) {
                notify(message, { type: "success" });
                if (url) {
                    setConfirmationLink(url);
                }
            }
        }
    }, [data, notify]);

    const copyConfirmationLink = useCallback(() => {
        navigator.clipboard.writeText(confirmationLink);
        notify(`Confirmation link was copied to the clipboard`, {
            type: "success"
        });
    }, [confirmationLink, notify]);

    return (
        <TopToolbar>
            {confirmationLink && (
                <Button
                    color="primary"
                    onClick={copyConfirmationLink}
                    label="Copy Link"
                />
            )}
            <Button
                color="primary"
                onClick={resetPassword}
                label="Reset Password"
                startIcon={<Reset />}
            />
            <ShowButton record={data} />
        </TopToolbar>
    );
};
