export const isVersionNumber = (version: string = ""): boolean => {
    const split = version.split(".");

    if (split.length !== 3) {
        return false;
    }

    for (const number of split) {
        if (isNaN(parseInt(number))) {
            return false;
        }
    }

    return true;
};
