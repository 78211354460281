import { ShowButton, useRecordContext } from "react-admin";

export interface ShowRelationButtonProps {
    /** the resource to connect to - is inserted into the basePath of the button - ex. "Users" */
    relatedResource: string;
    /** the property of the current record that relates to the record to show - ex. "UserId" */
    property: string;
}

/**
 * Inside of a Datagrid or another component with record context this button will redirect to the Show page of another record associated with the current record
 */
export const ShowRelationButton: React.FC<ShowRelationButtonProps> = ({
    relatedResource,
    property
}) => {
    const record = useRecordContext();

    return <ShowButton record={record} />;
};
