import { parse } from "query-string";

export const getQueryParam = (key: string): string | null => {
    const href = window.location.href;

    if (!href.includes("?")) {
        return null;
    }

    const qs = href.split("?")[1];
    return parse(qs)[key] as string | null;
};

export const stringifyIfDefined = (object: Object): string => {
    let out: string = "";
    for (const [key, value] of Object.entries(object)) {
        if (value) {
            out += `${key}=${value}&`;
        }
    }

    if (out) {
        return out.slice(0, -1);
    }

    return out;
};
