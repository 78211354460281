import {
    Show as ReactAdminShow,
    SimpleShowLayout,
    BooleanField,
    NumberField,
    TextField,
    DateField
} from "react-admin";

export const Show: React.FC = () => (
    <ReactAdminShow>
        <SimpleShowLayout>
            <TextField source="Name" />
            <TextField source="BillingScheme" />
            <TextField source="ProductId" />
            <NumberField
                source="Amount"
                options={{ style: "currency", currency: "USD" }}
            />
            <NumberField
                source="RewardAmount"
                options={{ style: "currency", currency: "USD" }}
            />
            <BooleanField source="Active" />
            <BooleanField source="IsPublic" />
            <BooleanField source="IsReoccurring" />
            <DateField source="CreatedAt" />
            <DateField source="UpdatedAt" />
        </SimpleShowLayout>
    </ReactAdminShow>
);
